import { Component, inject, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { DefaultService } from 'src/app/utils/api';
import { Notification } from '../hotline/hotline.component';
import moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationDetailModalComponent } from 'src/app/components/modals/hotlines/notification-detail-modal/notification-detail-modal.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-hotline-dashboard',
    templateUrl: './hotline-dashboard.component.html',
    styleUrl: './hotline-dashboard.component.scss'
})
export class HotlineDashboardComponent implements OnInit {
    configService = inject(ConfigService);
    defaultService = inject(DefaultService);
    modalService = inject(NgbModal);
    router = inject(Router);
    route = inject(ActivatedRoute);

    appOverviewUrl: string;
    showMax: number = 10;
    totalRows: number;

    ready: boolean = false;
    loading: boolean = false;
    showAll: boolean = false;

    tableHeads: {
        name: string;
        code: string;
        sortable: boolean;
        width?: string;
    }[] = [
        { name: 'Ervaring', code: 'ervaringIndicator', sortable: true, width: '10%' },
        { name: 'Medicijn', code: 'medicijn', sortable: true, width: '30%' },
        { name: 'Melding', code: 'ervaringDescr', sortable: true, width: '45%' },
        { name: 'Aangemaakt op', code: 'createTS', sortable: true, width: '15%' },
        { name: '', code: 'actions', sortable: false, width: '0.1%' }
    ];

    data: Notification[] = [];

    ngOnInit() {
        this.appOverviewUrl = this.configService.getConfig().appOverviewUrl;
        this.getHotlines();
        const detailId = this.route.snapshot.queryParamMap.get('notificationID');
        if (detailId) this.openDetail(detailId);
    }

    async getHotlines() {
        this.loading = true;
        const notifications = await lastValueFrom(
            this.defaultService.notificationsGetNotifications(0, undefined, undefined, false)
        );

        this.totalRows = notifications.rows;
        this.data = notifications?.data.map((notification) => {
            const actions = [];
            const deleteAction = {
                name: 'Verwijderen',
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirmDelete: true
            };
            actions.push(deleteAction);

            const editAction = {
                name: 'Bewerken',
                code: 'edit',
                icon: 'pencil'
            };
            actions.push(editAction);

            return {
                ...notification,
                id: notification.id,
                ervaringIndicator: {
                    type: 'icon',
                    iconPath: `/assets/img/icons/experience_score-${notification.ervaringIndicator}.svg`,
                    classList: 'fontweight-500 text-color experience-icon'
                },
                medicijn: {
                    type: 'default',
                    value: notification.medicijn
                },
                ervaringDescr: {
                    type: 'default',
                    value: notification.ervaringDescr
                },
                createTS: {
                    type: 'default',
                    value: moment(notification.createTS).format('DD-MM-YYYY')
                },
                actions: {
                    type: 'actions',
                    actions: actions
                }
            } as Notification;
        });

        this.loading = false;
        this.ready = true;
    }

    openDetail(id: string) {
        this.router.navigate([], {
            queryParams: {
                notificationID: id
            },
            queryParamsHandling: 'merge'
        });

        const modalRef = this.modalService.open(NotificationDetailModalComponent, {
            windowClass: 'main-modal detail-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });

        modalRef.dismissed.subscribe({
            next: (next) => {
                this.router.navigate([], {
                    queryParams: {
                        notificationID: undefined
                    },
                    queryParamsHandling: 'merge'
                });
            }
        });

        modalRef.closed.subscribe({
            next: (next) => {
                this.router.navigate([], {
                    queryParams: {
                        notificationID: undefined
                    },
                    queryParamsHandling: 'merge'
                });
                this.getHotlines();
            }
        });
    }
}
