<span class="close-modal-outside"><i (click)="dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">

    @if (!ready) {
        <app-spinner [centerVertically]="true"></app-spinner>
    } @else {
        @if (submitting) {
            <div style="height: 100%;">
                <app-spinner [centerVertically]="true" [fixed]="true"></app-spinner>
            </div>
        }

        <div class="d-flex detail-modal"  [attr.inert]="submitting ? '' : null">

            <!-- start form -->
            <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
               
                <!-- header -->
                <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                    <h1 style="min-height: 36px;">
                        @if (!notification.approved) {
                            Melding goedkeuren
                        } @else if (mode === 'view') {
                            Detail melding
                        } @else if (mode === 'edit') {
                            Wijzig melding
                        }
                    </h1>
                   
                    @if (notification.approved) {
                        <div class="h-100 d-flex align-items-center"  [class.saving]="submitting">
                            @if (mode !== 'edit') {
                                <button class="button tertiary ml-xs" (click)="mode = 'edit'" [class.disabled]="saving"><i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>Wijzigen</span></button>
                                @if (notification?.approved) {
                                    <div class="position-relative ml-xs" appClickOutside (clickOutside)="confirmDelete = false">
                                        <button class="button tertiary delete-red" (click)="confirmDelete = true" [class.disabled]="submitting"><i class="im-icon im-icon-trash delete-red icon-l mr-xxxs" style="transform: translateY(-1px);"></i><span>Verwijderen</span></button>
                                        <app-confirm-delete (confirm)="deleteNotification();" (cancel)="confirmDelete = false" [open]="confirmDelete" positionClass="left" positionClassV="bottom"></app-confirm-delete>
                                    </div>
                                }
                            }
                            @else {
                                <button class="button tertiary ml-xs" (click)="resetForm(); mode = 'view'" [class.disabled]="saving"><span>Annuleren</span></button>
                                <button class="button primary ml-xs" (click)="update()" [class.disabled]="saving"><i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>Opslaan</span></button>
                            }
                        </div>
                    }
                    
                </div>



                <div class="detail-modal-body">
                    <!-- content -->

                    <section>
                        <h2>Melding</h2>

                        <div class="row display-row gap-16">
                            <div class="display-content col-sm-4">
                                <div class="display-label">Ervaring</div>
                                <div class="display-value d-flex flex-row">
                                    <i class="im-icon asset-icon" [style.background-image]="'url(/assets/img/icons/experience_score-' + notification.ervaringIndicator + '.svg)'"></i>
                                </div>
                            </div>
                            <div class="display-content col-sm-7">
                                <div class="display-label">Medicijn</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{notification.medicijn | display}}</span>
                                </div>
                            </div>
                            <div class="display-content col-sm-8">
                                @if (!notification.approved) {
                                    <app-field-textarea 
                                        [model]="FORM?.ervaringDescr" 
                                        (modelChange)="FORM.ervaringDescr = $event; formChange();" 
                                        [label]="'Melding'"
                                        [expanded]="true"
                                        [error]="validation?.ervaringDescr">
                                    </app-field-textarea>
                                } @else {
                                    @if (mode !== 'edit') {
                                        <div class="display-label">Melding</div>
                                        <div class="display-value d-flex flex-row">
                                            <span class="page-content" [innerHTML]="notification.ervaringDescr | display | safe : 'html'"></span>
                                        </div>
                                    } @else {
                                        <app-field-textarea 
                                            [model]="FORM?.ervaringDescr" 
                                            (modelChange)="FORM.ervaringDescr = $event; formChange();" 
                                            [label]="'Melding'"
                                            [expanded]="true"
                                            [error]="validation?.ervaringDescr">
                                        </app-field-textarea>
                                    }
                                }
                                
                            </div>
                        </div>
                    </section>

                    <div class="sep"></div>

                    <section>
                        <h2>Extra informatie</h2>

                        <div class="row display-row gap-16">
                            <div class="display-content col-sm-4">
                               
                                <div class="display-label">Ervaring over</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{notification?.onderwerpen | array}}</span>
                                </div>
                            </div>
                            <div class="display-content col-sm-4">
                                <div class="display-label">Leeftijd</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{notification?.leeftijd | display}}</span>
                                </div>
                            </div>
                            <div class="display-content col-sm-4">
                                <div class="display-label">Geslacht</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{notification?.geslacht | display}}</span>
                                </div>
                            </div>
                            <div class="display-content col-sm-4">                                
                                <div class="display-label">Reden gebruik</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{notification?.redenGebruik | display}}</span>
                                </div>
                            </div>
                            <div class="display-content col-sm-4">
                                <div class="display-label">Ervaring nadien</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{notification?.ervaringNadien?.title | display}}</span>
                                </div>
                            </div>
                            <div class="display-content col-sm-4">
                                <div class="display-label">Zorgverlener</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{notification?.hulpverlener | yesNoUnknown}}</span>
                                </div>
                                
                            </div>
                        </div>

                    </section>
                 
                    @if (!notification.approved) {
                        <section class="reaction-panel">
                            <h2>Deze ervaring</h2>
                            <p>Beslis of je deze aanvraag wil goedkeuren of verwijderen</p>
                            <div class="button-wrap"  [class.saving]="submitting">
                                <button class="button primary" (click)="approve()" [class.disabled]="saving"><i class="im-icon im-icon-check-round-filled icon-l mr-xxxs"></i><span>Goedkeuren</span></button>
                                <div class="position-relative" appClickOutside (clickOutside)="confirmDelete = false">
                                    <button class="button tertiary delete-red" (click)="confirmDelete = true" [class.disabled]="submitting"><i class="im-icon im-icon-trash delete-red icon-l mr-xxxs" style="transform: translateY(-1px);"></i><span>Verwijderen</span></button>
                                    <app-confirm-delete (confirm)="deleteNotification();" (cancel)="confirmDelete = false" [open]="confirmDelete" positionClass="left" positionClassV="right"></app-confirm-delete>
                                </div>
                            </div>
                        </section>
                    }
                </div>
                
                <app-history
                    [createTS]="notification.createTS"
                    [createUsername]="notification.createUsername"
                    [editTS]="notification.editTS"
                    [editUsername]="notification.editUsername"
                ></app-history>

            </div>
        </div>
    }
    
</div>