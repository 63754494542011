import { Component, inject, Input, OnInit, output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MeldpuntPagePayload } from 'backend/src/models/meldpunt/page';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { DefaultService } from 'src/app/utils/api';
export type PageDetailMode = 'create' | 'edit' | 'view';

type PageDetail = {
    id?: string;
    parentId?: string;
    titel: string;
    inhoud: string;
    editPageTS: string;
    createTS?: string;
    createUsername?: string;
    editTS?: string;
    editUsername?: string;
    gepubliceerd: boolean;
    subpage?: boolean;
    subpaginas?: SubpagePageOverview[];
};

type SubpagePageOverview = {
    id?: string;
    titel: string;
    editUsername?: string;
    editTS?: string;
    createUsername?: string;
    createTS?: string;
    // actions: any;
};

@Component({
    selector: 'app-hotlines-page-detail-modal',
    templateUrl: './hotlines-page-detail-modal.component.html',
    styleUrl: './hotlines-page-detail-modal.component.scss'
})
export class HotlinesPageDetailModalComponent implements OnInit {
    activeModal = inject(NgbActiveModal);
    modalService = inject(NgbModal);
    route = inject(ActivatedRoute);
    router = inject(Router);
    defaultService = inject(DefaultService);
    toastr = inject(ToastrService);

    @Input() mode: PageDetailMode = undefined;
    ready: boolean = false;
    saving: boolean;
    submitting: boolean = false;
    changes: boolean = false;

    id: string = undefined;
    page: PageDetail;
    FORM: PageDetail;
    validation: any;
    updateOverview = output();

    confirmDelete: string = undefined;

    ngOnInit(): void {
        this.mode = this.mode ? this.mode : (this.route.snapshot.queryParamMap.get('mode') as PageDetailMode);
        this.id = this.route.snapshot.queryParamMap.get('pageID');
        if (this.mode === 'create') {
            this.initEmptySubpage(this.id);
        } else {
            this.getPage(this.id);
        }
    }

    initEmptySubpage(parentId: string) {
        this.page = {
            id: undefined,
            parentId: this.id,
            titel: undefined,
            inhoud: undefined,
            createTS: undefined,
            editPageTS: undefined,
            createUsername: undefined,
            editTS: undefined,
            editUsername: undefined,
            gepubliceerd: false,
            subpage: true
        };

        this.resetForm();
        this.ready = true;

        setTimeout(() => {
            (document.activeElement as HTMLElement)?.blur();
            this.changes = false;
        }, 1);
    }

    async getPage(id: string) {
        this.page = await lastValueFrom(this.defaultService.meldpuntPagesGetPageDetail(id));
        this.resetForm();
        this.ready = true;
        this.submitting = false;
        this.validation = false;
    }

    openPage(id: string, mode?: PageDetailMode) {
        this.activeModal.dismiss({
            routeTo: {
                id: id,
                mode: mode || 'view'
            }
        });
    }

    createSubpage() {
        const createSubpageModal = this.modalService.open(HotlinesPageDetailModalComponent, {
            windowClass: 'add-modal',
            beforeDismiss: () => {
                return createSubpageModal.componentInstance.canExit && createSubpageModal.componentInstance.canExit();
            }
        });
        createSubpageModal.componentInstance.mode = 'create';

        createSubpageModal.closed.subscribe({
            next: (next) => {
                this.updateOverview.emit();
                this.getPage(this.id);
            }
        });
        createSubpageModal.componentInstance.updateOverview.subscribe(() => {
            this.getPage(this.id);
            this.updateOverview.emit();
        });
    }

    submit() {
        this.submitting = true;
        const payload: MeldpuntPagePayload = {
            id: this.FORM?.id,
            parentId: this.FORM?.parentId,
            titel: this.FORM?.titel,
            editPageTS: this.FORM?.editPageTS,
            inhoud: this.FORM?.inhoud,
            gepubliceerd: this.FORM?.gepubliceerd
        };

        this.defaultService.meldpuntPagesCreateOrUpdatePage(payload).subscribe({
            next: async (next) => {
                if (this.mode === 'create')
                    this.toastr.success('Subpagina werd succesvol aangemaakt', 'Subpagina aangemaakt');
                else this.toastr.success('Pagina werd succesvol gewijzigd', 'Pagina gewijzigd');

                this.changes = false;
                this.submitting = false;
                this.updateOverview.emit();
                await this.getPage(next.id);
                this.mode = 'view';
            },
            error: (error) => {
                this.submitting = false;
                this.validation = error.error.details;
            }
        });
    }

    // deleteSubpage(id: string) {
    //     this.submitting = true;
    //     this.defaultService.meldpuntPagesDeletePage(id).subscribe({
    //         next: (next) => {
    //             this.toastr.success('Subpagina werd succesvol verwijderd', 'Subpagina verwijderd');
    //             this.changes = false;
    //             this.submitting = false;
    //             this.updateOverview.emit();
    //             this.getPage(this.id);
    //         },
    //         error: (error) => {
    //             this.submitting = false;
    //             this.validation = error.error.details;
    //         }
    //     });
    // }
    deletePage(id: string, dismiss: boolean = false) {
        this.submitting = true;
        this.defaultService.meldpuntPagesDeletePage(id).subscribe({
            next: (next) => {
                this.toastr.success('Subpagina werd succesvol verwijderd', 'Subpagina verwijderd');
                this.changes = false;
                this.submitting = false;
                this.updateOverview.emit();
                dismiss ? this.activeModal.dismiss() : this.getPage(this.id);
            },
            error: (error) => {
                this.submitting = false;
                this.validation = error.error.details;
            }
        });
    }

    changeSequence(from: number, to: number) {
        this.saving = true;
        this.submitting = true;

        const subpageIds = this.page?.subpaginas?.map((x) => x.id);
        const x = subpageIds?.splice(from, 1)[0];
        subpageIds.splice(to, 0, x);

        this.defaultService.meldpuntPagesChangeSubpageSequence({ subpagesIds: subpageIds }, this.id).subscribe({
            next: (next) => {
                this.saving = false;
                this.toastr.success('Volgorde werd successvol gewijzigd.', 'Volgorde gewijzigd');
                this.getPage(this.id);
                this.updateOverview.emit();
            },
            error: (error) => {
                this.saving = false;
                this.submitting = false;
            }
        });
    }

    resetForm() {
        this.FORM = structuredClone(this.page);
        this.changes = false;
    }

    formChange() {
        this.changes = true;
    }

    dismiss() {
        if (this.canExit()) {
            this.activeModal.dismiss();
        }
    }

    canExit(): boolean {
        if (
            (!this.changes && !this.submitting) ||
            confirm('Er zijn niet-opgeslagen wijzigingen. Weet u weker dat u deze pagina wil verlaten?')
        ) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }
}
