import { Component, inject, OnInit, output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { DefaultService, MeldpuntNewsitemPayload } from 'src/app/utils/api';
import { CroppedImages, ThemeImagesComponent } from '../../theme-images/theme-images.component';
import { CropperPosition } from 'ngx-image-cropper';
import { HelpersService } from 'src/app/services/helpers.service';
export type NewsDetailMode = 'create' | 'edit' | 'view';
type NewsDetail = {
    titel: string;
    inhoud: string;
    homepage?: boolean;
    gepubliceerd: boolean;
    themeImage?: ThemeImage;

    createTS?: string;
    editTS?: string;
    createUsername?: string;
    editUsername?: string;
};
interface ThemeImage {
    title?: string;
    source?: string;
    sourceWidth?: number;
    sourceFile?: File;
    articleUrl?: string;
    articleCrop?: CropperPosition;
    thumbnailUrl?: string;
    thumbnailCrop?: CropperPosition;
}
@Component({
    selector: 'app-hotline-news-detail-modal',
    templateUrl: './hotline-news-detail-modal.component.html',
    styleUrl: './hotline-news-detail-modal.component.scss'
})
export class HotlineNewsDetailModalComponent implements OnInit {
    activeModal = inject(NgbActiveModal);
    route = inject(ActivatedRoute);
    router = inject(Router);
    defaultService = inject(DefaultService);
    toastr = inject(ToastrService);
    modalService = inject(NgbModal);
    helpersService = inject(HelpersService);

    mode: NewsDetailMode = undefined;
    ready: boolean = false;
    loading: boolean = false;
    saving: boolean = false;
    submitting: boolean = false;
    submittingImages: boolean = false;
    changes: boolean = false;
    validation: any;
    updateOverview = output();

    id: string = undefined;
    data: NewsDetail;
    FORM: NewsDetail;
    croppedImages: CroppedImages = undefined;

    confirmDelete = false;

    yesNoOptions = [
        { title: 'Ja', value: 'yes' },
        { title: 'Nee', value: 'no' }
    ];

    ngOnInit() {
        this.id = this.route.snapshot.queryParamMap.get('newsId');
        this.mode = this.route.snapshot.queryParamMap.get('mode') as NewsDetailMode;
        if (this.mode === 'create') this.createEmptyNewsitem();
        else this.getNewsItem(this.id);
    }

    createEmptyNewsitem() {
        this.data = {
            titel: undefined,
            inhoud: undefined,
            gepubliceerd: false,
            homepage: false,
            themeImage: undefined
        };
        this.resetForm();
    }

    async getNewsItem(id: string) {
        await lastValueFrom(this.defaultService.meldpuntNewsitemsGetNewsitemDetail(id)).then((next) => {
            this.data = {
                ...next,
                titel: next.titel,
                inhoud: next.inhoud,
                themeImage: {
                    title: next.origineelAfbeeldingNaam,
                    source: next.origineelAfbeeldingURL,
                    sourceFile: undefined,
                    articleUrl: next.detailAfbeeldingURL,
                    articleCrop: next.detailAfbeeldingCrop ? JSON.parse(next.detailAfbeeldingCrop) : undefined,
                    thumbnailUrl: next.thumbnailAfbeeldingURL,
                    thumbnailCrop: next.thumbnailAfbeeldingCrop ? JSON.parse(next.thumbnailAfbeeldingCrop) : undefined
                }
            };
        });
        this.resetForm();
    }

    resetForm() {
        if (this.canExit()) this.FORM = structuredClone(this.data);
        this.changes = false;
        this.loading = false;
        this.ready = true;
    }

    async deleteItem() {
        this.submitting = true;

        this.defaultService.meldpuntNewsitemsDeleteNewsitem(this.id).subscribe({
            next: (next) => {
                this.toastr.success('Niewsitem werd successvol verwijderd', 'Nieuwsitem verwijderd');
                this.changes = false;
                this.submitting = false;
                this.updateOverview.emit();
                this.activeModal.dismiss();
            },
            error: (error) => {
                this.submitting = false;
                this.validation = error.error.details;
            }
        });
    }

    async submit() {
        this.submitting = true;
        const updateOrCreatePayload: MeldpuntNewsitemPayload = {
            id: this.id ? this.id : undefined,
            titel: this.FORM?.titel,
            inhoud: this.FORM?.inhoud,
            gepubliceerd: this.FORM?.gepubliceerd,
            homepage: this.FORM?.homepage
        };

        try {
            const result = await lastValueFrom(
                this.defaultService.meldpuntNewsitemsUpdateOrCreateNewsitem(updateOrCreatePayload)
            );

            this.id = result.id;
            this.updateOverview.emit();
            if (this.mode === 'create' || this.id)
                this.toastr.success('Nieuwsitem werd succesvol aangemaakt', 'Nieuwsitem aangemaakt');
            else this.toastr.success('Nieuwsitem werd succesvol gewijzigd', 'Nieuwsitem gewijzigd');

            await this.uploadImages(result.id);
            this.submittingImages = false;
            this.toastr.success('Afbeelding werd successvol geüpload', 'Afbeelding geüpload');

            this.submitting = false;
            this.changes = false;
            this.submitting = false;
            this.validation = undefined;
            await this.getNewsItem(result.id);
            this.mode = 'view';
        } catch (error) {
            // this.FORM.themeImage = this.data?.themeImage
            //     ? structuredClone(this.data?.themeImage)
            //     : this.FORM?.themeImage;
            this.validation = error.error?.details;
            this.submitting = false;
        }
    }

    async uploadImages(id: string) {
        let original;
        let article;
        let thumb;

        this.submittingImages = true;

        if (this.croppedImages?.original?.file) {
            original = await lastValueFrom(
                this.defaultService.meldpuntNewsitemsUploadImage(
                    this.croppedImages.original.file,
                    'origineel',
                    id,
                    undefined,
                    this.croppedImages.original.fileWidth
                )
            ).catch((error) => {
                const imgvalidation = error?.error?.details;
                this.validation = {
                    ...this.validation,
                    ...imgvalidation
                };

                this.toastr.error(
                    'Uploaden van de afbeelding is niet gelukt.',
                    'Er liep iets mis met het uploaden van de afbeelding'
                );
                this.FORM.themeImage = {
                    ...this.FORM?.themeImage,
                    articleUrl: undefined,
                    thumbnailUrl: undefined
                };
                this.croppedImages = { ...this.croppedImages, article: undefined, thumb: undefined };

                // original: {
                //     file?: File;
                //     fileUrl?: string;
                //     fileWidth?: number;
                // };
                // article: {
                //     file: File;
                //     croppedPosition: CropperPosition;
                // };
                // thumb: {
                //     file: File;
                //     croppedPosition: CropperPosition;
                // };
                this.submitting = false;
                this.submittingImages = false;

                throw error;
            });
        }
        if (this.croppedImages?.article?.file) {
            article = lastValueFrom(
                this.defaultService.meldpuntNewsitemsUploadImage(
                    this.croppedImages.article.file,
                    'detail',
                    id,
                    JSON.stringify(this.croppedImages.article?.croppedPosition)
                )
            );
        }
        if (this.croppedImages?.thumb?.file) {
            thumb = lastValueFrom(
                this.defaultService.meldpuntNewsitemsUploadImage(
                    this.croppedImages.thumb.file,
                    'thumbnail',
                    id,
                    JSON.stringify(this.croppedImages.thumb?.croppedPosition)
                )
            );
        }

        return Promise.all([original, article, thumb]).then(([origineel, article, thumb]) => {
            this.FORM.themeImage = {
                ...this.FORM.themeImage,
                source: origineel?.url,
                sourceWidth: origineel?.width,
                articleCrop: article?.crop ? JSON.parse(article.crop) : undefined,
                articleUrl: article?.url,
                thumbnailCrop: thumb?.crop ? JSON.parse(thumb.crop) : undefined,
                thumbnailUrl: thumb?.url
            };
        });
    }

    // theme image
    imageSelect($event: File[]) {
        const file = $event[$event.length - 1];
        if (!file) return;
        this.FORM.themeImage = {
            ...this.FORM?.themeImage,
            sourceFile: file,
            title: file.name
        };

        const modal = this.modalService.open(ThemeImagesComponent, {
            windowClass: 'modal--theme-images'
        });
        modal.componentInstance.imageFile = file;
        modal.closed.subscribe(async (result: CroppedImages) => {
            this.croppedImages = result;

            this.submitting = true;
            if (this.id) {
                delete this.validation?.afbeelding;
                await this.uploadImages(this.id).then(() => {
                    this.toastr.success('Afbeelding werd succesvol opgeslagen', 'Afbeelding opgeslagen');
                    this.submittingImages = false;
                });
            } else {
                delete this.validation?.afbeelding;
                this.FORM.themeImage = {
                    title: result?.original?.file?.name,
                    articleUrl: URL.createObjectURL(result.article.file),
                    articleCrop: result.article.croppedPosition,
                    thumbnailUrl: URL.createObjectURL(result.thumb.file),
                    thumbnailCrop: result.thumb.croppedPosition
                };
            }

            this.submitting = false;
        });
    }

    themeImageEdit() {
        const source = this.FORM.themeImage?.source;
        if (!source) return;
        this.formChange();
        const modal = this.modalService.open(ThemeImagesComponent, {
            windowClass: 'modal--theme-images'
        });
        modal.componentInstance.imageURL = source;
        modal.componentInstance.imageName = this.FORM.themeImage?.title;
        modal.componentInstance.imageWidth = this.FORM.themeImage?.sourceWidth;

        modal.componentInstance.cropPositionArticleInput = this.FORM.themeImage?.articleCrop;
        modal.componentInstance.cropPositionThumbInput = this.FORM.themeImage?.thumbnailCrop;
        modal.closed.subscribe(async (result: CroppedImages) => {
            this.croppedImages = result;

            this.FORM.themeImage = {
                ...this.FORM.themeImage,
                articleUrl: URL.createObjectURL(result.article.file),
                articleCrop: result.article.croppedPosition,
                thumbnailUrl: URL.createObjectURL(result.thumb.file),
                thumbnailCrop: result.thumb.croppedPosition
            };
        });
    }
    removeThemeImage() {
        delete this.FORM.themeImage;
    }

    formChange() {
        this.changes = true;
    }

    dismiss() {
        if (this.canExit()) {
            this.activeModal.dismiss();
        }
    }

    canExit(): boolean {
        if (
            (!this.changes && !this.submitting && !this.submittingImages) ||
            confirm('Er zijn niet-opgeslagen wijzigingen. Ben je zeker dat je wil annuleren?')
        ) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }
}
